<template>
  <div class="ui-progress">
    <span class="ui-progress__label">{{ label }}</span>

    <div
      class="ui-progress__bar"
      :style="style"
    >
      <span
        v-if="barLabel"
        class="ui-progress__bar-label"
      >{{ barLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiProgressBar',

  props: {
    progress: String,
    barLabel: String,
    label: String
  },

  computed: {
    style() {
      return `--progress: ${ this.progress ?? 0 }%;`
    }
  }
}
</script>
