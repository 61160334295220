<script>
import UiProgress from '@/components/ui/progress-bar.vue'

export default {
  name: 'ViewChallenge',

  components: {
    UiProgress
  },

  data() {
    return {
      loading: false,
      challenge: null,
      completions: [],
      brand: null,
    }
  },

  computed: {
    description() {
      if(this.challenge?.descr && this.challenge?.descr !== '') {
        return this.challenge.descr
      }

      return null
    },

    descriptionMini() {
      if(this.challenge?.descrMini && this.challenge?.descrMini !== '') {
        return this.challenge.descrMini
      }

      return null
    },

    hasBanner() {
      return !!this.brand?.banner
    },

    hasBrand() {
      return !!this.brand?.name
    },

    hasBrandLogo() {
      return !!this.brand?.logo
    },

    hasDescription() {
      return !!this.description
    },

    hasDescriptionMini() {
      return !!this.descriptionMini
    },

    hasRewardDescription() {
      return !!this.challenge?.rewardDescr
    },

    id() {
      return this.$route.params.id
    },

    imageUrl() {
      return this.challenge.logo?.url
    },

    overallProgress() {
      return `${ (this.challenge.completionsCount / this.challenge.globalAmountRewards) * 100 }`
    },

    overallProgressBarLabel() {
      return `${ this.challenge.completionsCount }/${ this.challenge.globalAmountRewards } (${ this.overallProgress }%)`
    },

    hasOverallProgression() {
      return !!this.challenge?.globalAmountRewards
    },

    userProgress() {
      return `${ (this.challenge.userCompletionsCount / this.challenge.userAmountRewards) * 100 }`
    },

    userProgressBarLabel() {
      return `${ this.challenge.userCompletionsCount }/${ this.challenge.userAmountRewards } (${ this.userProgress }%)`
    },

    hasUserProgression() {
      return !!this.challenge?.userAmountRewards
    },

    name() {
      return this.challenge?.name
    }
  },

  methods: {
    async init() {
      try {
        this.loading = true

        this.challenge = await this.$challenges.getChallenge(this.id)
        this.completions = await this.$challenges.getCompletions(this.id)
        const brands = await this.$brands.getBrands()

        this.challenge.brand = brands.find(b => b.projectId === this.challenge.projectId)
        this.brand = await this.$brands.getBrand({ id: this.challenge.brand?.id })
      } catch (e) {
        $console.error(e)
        this.$router.push({ name: 'sayl-connect_challenges-challenges' })
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>

<template>
  <layout-entity
    class="module-challenges challenge"
    :key="hasBanner"
    :loading="loading"
  >
    <template
      v-if="hasBanner"
      v-slot:banner
    >
      <ui-illustration
        :alt="brand.banner.alt.all"
        :src="brand.banner.url.all"
        :type="brand.banner.type"
      />
    </template>

    <ui-card
      class="challenge__card"
      :title="$basil.get(challenge, 'name')"
      illustration-format="banner"
    >
      <template v-slot:illustration>
        <ui-illustration
          :alt="challenge.name"
          :src="imageUrl"
        />
      </template>

      <template
        v-if="hasDescriptionMini"
        v-slot:subtitle
      >
        <div
          v-html="descriptionMini"
          class="challenge__challenge-description"
        ></div>
      </template>

      <template v-slot:footer>
        <div
          v-if="$basil.get(challenge, 'brand.name')"
          class="challenge__challenge-brand"
        >
          <span class="ui-card__subtitle">{{ $basil.get(challenge, 'brand.name') }}</span>
        </div>
      </template>
    </ui-card>

    <template v-slot:informations>
      <!-- <ui-panel v-if="hasDescriptionMini">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.challenge_description_mini_title') }}</h4>
        </template>

        <div class="body">
          <div
            v-html="descriptionMini"
            class="editor"
          ></div>
        </div>
      </ui-panel> -->

      <ui-panel v-if="hasDescription">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.challenge_description_title') }}</h4>
        </template>

        <div class="body">
          <div
            v-html="description"
            class="editor"
          ></div>
        </div>
      </ui-panel>

      <ui-panel v-if="hasRewardDescription">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.challenge_reward_description_title') }}</h4>
        </template>

        <div class="body">
          <div
            v-html="challenge.rewardDescr"
            class="editor"
          ></div>
        </div>
      </ui-panel>

      <ui-panel v-if="hasOverallProgression || hasUserProgression">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.challenge_progress_title') }}</h4>
        </template>

        <div class="body">
          <ui-progress
            v-if="hasOverallProgression"
            :progress="overallProgress"
            :barLabel="overallProgressBarLabel"
            :label="$t('conn3ct-wallet.challenge_overall_progression_title')"
          />

          <ui-progress
            v-if="hasUserProgression"
            :progress="userProgress"
            :barLabel="userProgressBarLabel"
            :label="$t('conn3ct-wallet.challenge_user_progression_title')"
          />
        </div>
      </ui-panel>

      <ui-panel v-if="hasBrand">
        <template v-slot:header>
          <div class="challenge__brand-header">
            <img
              v-if="hasBrandLogo"
              class="challenge__brand-logo"
              :src="brand.logo.url.all"
              :alt="brand.logo.alt.all"
            />

            <h4 class="title">{{ brand.name }}</h4>
          </div>
        </template>

        <div
          v-if="brand.description"
          class="body"
        >
          <div
            v-html="brand.description"
            class="editor"
          ></div>
        </div>
      </ui-panel>

      <ui-panel v-if="completions.length">
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.challenge_completion_history_title') }}</h4>
        </template>

        <div class="body challenge__completions">
          <div
            v-for="(completion, i) in completions"
            class="challenge__completion"
            :key="i"
          >
            <div class="challenge__completion-left">
              <span>{{ completion.created.toLocaleString({day: 'numeric', month: 'numeric', hour: 'numeric', minute: '2-digit', year: 'numeric'}) }}</span>
              <span class="challenge__completion-info">{{ $t(`conn3ct-wallet.challenge_reward_type_${ challenge.rewardType }`) }}</span>
            </div>

            <div class="challenge__completion-right">
              <span class="challenge__completion-info">{{ challenge.name }}</span>
            </div>
          </div>
        </div>
      </ui-panel>
    </template>
  </layout-entity>
</template>
